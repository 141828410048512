import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

export function MonapaImage() {
  let imagaNum = Math.floor( Math.random() * 15);
  const image = {
    0: <div><StaticImage src="../images/amarin_01.png" alt="MonapartyFreeLogo" /></div>,
    1: <div><StaticImage src="../images/ano_03.png" alt="MonapartyFreeLogo" /></div>,
    2: <div><StaticImage src="../images/6416_01.jpg" alt="MonapartyFreeLogo" /></div>,
    3: <div><StaticImage src="../images/usagi_02.png" alt="MonapartyFreeLogo" /></div>,
    4: <div><StaticImage src="../images/gem_01.png" alt="MonapartyFreeLogo" /></div>,
    5: <div><StaticImage src="../images/gem_04.png" alt="MonapartyFreeLogo" /></div>,
    6: <div><StaticImage src="../images/izuru_01.png" alt="MonapartyFreeLogo" /></div>,
    7: <div><StaticImage src="../images/kate_01.png" alt="MonapartyFreeLogo" /></div>,
    8: <div><StaticImage src="../images/kate_03.png" alt="MonapartyFreeLogo" /></div>,
    9: <div><StaticImage src="../images/kotori_01.png" alt="MonapartyFreeLogo" /></div>,
    10: <div><StaticImage src="../images/mekabu_01.png" alt="MonapartyFreeLogo" /></div>,
    11: <div><StaticImage src="../images/momo_01.jpg" alt="MonapartyFreeLogo" /></div>,
    12: <div><StaticImage src="../images/momota_01.png" alt="MonapartyFreeLogo" /></div>,
    13: <div><StaticImage src="../images/neszta_01.png" alt="MonapartyFreeLogo" /></div>,
    14: <div><StaticImage src="../images/zugaan_02.jpg" alt="MonapartyFreeLogo" /></div>,
    // 15: <div><StaticImage src="../images/asterisk_01.png" alt="MonapartyFreeLogo" /></div> // sorry. but it's so cool
  }
  return image[imagaNum];
}

export function AttentionImage() {
  let imagaNum = Math.floor( Math.random() * 15);
  const image = {
    0: <div><StaticImage src="../images/asterisk_02.png" alt="MonapartyFreeLogo" /></div>
  }
  return image[0];
}
