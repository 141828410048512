import React from "react";

const Footer = () => {
  return (
    <footer className="">
      <div className="px-12 py-12 w-full flex justify-center items-center">
        <p>© 2021- kotaroo.works</p>
      </div>
    </footer>
  );
}

export default Footer;
