import React, { useReducer } from "react";
import Footer from './Footer';
import Header from './Header';
import SEO from '../Seo';
import "../../style/global.css"

const initialState = { mpurseAddr: "" }

function reducer(state, action) {
  switch (action.type) {
    case 'ADDR_STATE':
      return {
        ...state,
        mpurseAddr: action.payload
      };
    default : 
      return state
  }
}

export const SiteContext = React.createContext();

const SiteProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <SiteContext.Provider value={{state, dispatch}}>
    {children}
  </SiteContext.Provider>
}

const Layout = ({ children }) => {
  return (
        <>
          <SEO />
            <div
              className="p-4 flex flex-col min-h-screen"
              style={{
                color: 'rgb(222, 255, 217)',
                backgroundColor: 'rgb(0, 190, 213)'
              }}
            >
            <SiteProvider>
              <Header />
              <main className="flex-grow">{children}</main>
              <Footer />
            </SiteProvider>
            
          </div>
        </>
  );
};

export default Layout;
