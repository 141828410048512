import React, {useState, useContext } from "react";
import { addressShort, getAddressMP } from "./Mpurse";
import { SiteContext } from "./layout/Layout";
import '../css/style.css';

function MpurseHeader() {
  const { state, dispatch } = useContext(SiteContext);
  const [ addrShort, setAddrShort ] = useState("Connect Mpurse \u{1F50C}");

  const getAddr = () => {
    try {
      getAddressMP().then((r) => {
        if(r === "") {
          setAddrShort("Connect Mpurse \u{1F50C}");
        } else {
          const addr = addressShort(r)
          setAddrShort(addr);
        }
        dispatch({ type:'ADDR_STATE', payload: r });
      });
    }
    catch { dispatch({ type:'ADDR_STATE', payload: "" }) }
  }

  if(state.mpurseAddr != "") {
    return (
      <div 
        className="font-semibold text-sm sm:text-xl md:text-xl sm:px-4 p-2 rounded-full border-2 border-dotted"
        style={{borderColor: 'rgb(222, 242, 217)'}}
      >
        <p>{ addrShort } &#x1f4a1;</p>
      </div>
    );
  }
  if(state.mpurseAddr === "") {
    return (
      <div 
        className="animate-pulse2 font-semibold text-xs sm:text-xl md:text-xl sm:px-4 pl-4 p-2 rounded-full border-2 border-dotted"
        style={{borderColor: 'rgb(222, 242, 217)'}}
      >
        <button onClick={ () => getAddr() }>{ addrShort }</button>
      </div>
    );
  }
  // if(state.mpurseAddr === "") {
  //   return (
  //     <div 
  //       className="animate-pulse2 font-semibold text-xs sm:text-xl md:text-xl sm:px-4 pl-4 p-2 rounded-full border-2 border-dotted"
  //       style={{borderColor: 'rgb(222, 242, 217)'}}
  //     >
  //       <Link to="/account">Connect Mpurse &#x1f50c;</Link>
  //     </div>
  //   );
  // }
}

export default MpurseHeader;