import { Link } from "gatsby";
import React from "react";
import { MonapaImage } from "../MonapaImage";
import MpurseHeader from "../MpurseHeader";
import "../../style/styles.css";

const Header = () => {
  return(
    <header className="px-2 md:px-8 md:py-8">
      <div className="flex justify-between items-center">
        <div
          className="px-4 py-6 md:px-12 md:py-8 rounded-lg border-2 border-dotted"
          style={{borderColor: 'rgb(222, 242, 217)'}}
          >
          <Link to="/" className=" flex sm:text-2xl md:text-3xl ">
            <div className="leading-0">
              <p className="sm:text-xl md:text-xl">XMP faucet</p>
              <p className="text-xs sm:text-xs md:text-xs ">
                枯渇したらおしまい
              </p>
            </div>
          </Link>
        </div>
        <div className="hidden sm:block w-1/4 md:w-1/6 mt-10">
          <MonapaImage />
        </div>
        <MpurseHeader />
      </div>
    </header>
  );
};

export default Header;
