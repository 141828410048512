import React from "react"
import { Helmet } from "react-helmet"

function SEO() {
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <title>XMP faucet</title>
      <meta name="description" content="Monaparty上で遊べるトークン！XMPを貰おう！" />
      <link rel="shortcut icon" href="https://faucet.kotaroo.work/favicon.png"></link>
      <meta property="og:url" content="https://faucet.kotaroo.work/monacard_yoko" />
      <meta property="og:title" content="XMP faucet" />
      <meta property="og:image" content="https://faucet.kotaroo.work/faucet_ogp.png" />
      <meta property="og:description" content="Monaparty上で遊べるトークン！XMPを貰おう！" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="XMP faucet" />
      <meta name="twitter:image" content="https://faucet.kotaroo.work/faucet_ogp.png" />
      <meta name="twitter:description" content="Monaparty上で遊べるトークン！XMPを貰おう！" />
      <meta name="twitter:site" content="@7101kerokero" />
    </Helmet>
  )
}
export default SEO;
